@use "sass:map";
@use "@angular/material" as mat;

@import "src/variables";
@import "src/mixins";
@import "src/constants";
@import "@angular/material/theming";

@mixin search-theme($theme) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $background-color: mat.get-color-from-palette($background, background);
  $card-background-color: mat.get-color-from-palette($background, card);
  $divider: mat.get-color-from-palette($foreground, divider);
  $top-bar-height: 70px;

  app-search {
    .mat-progress-bar {
      height: 3px;
      margin-top: -3px;
    }

    .main-outlet {
      background-color: $background-color;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      font-size: 14px;

      .top-bar {
        min-height: $top-bar-height;
        height: $top-bar-height;
        background-color: $background-color;
        border-bottom: 1px solid $divider;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 0 15px;

        .toggle-button-container {
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;

          .mat-button-toggle-group {
            padding: 3px;
            background-color: $card-background-color;

            .mat-button-toggle {
              min-width: 9em;
            }
          }

          .label {
            font-size: 0.7em;
            padding-top: 2px;
            margin-bottom: -10px;
          }
        }

        .search-wrapper {
          flex: 1;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;

          .mat-form-field {
            flex: 0.5;
            max-width: 30em;

            .search-icon {
              cursor: pointer;
            }

            .mat-error {
              margin-top: 15px;
            }
          }
        }
      }

      .secondary-outlet {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        width: 100%;

        .search-results {
          max-height: 100%;
          background-color: $card-background-color;
          display: flex;
          flex-flow: column;
          flex: 1;
        }
      }
    }
  }
}
