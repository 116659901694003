@use "sass:map";
@use "@angular/material" as mat;

@import "src/variables";
@import "@angular/material/theming";

@mixin emergency-contact-validator-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $background-color: mat.get-color-from-palette($background, background);
  $is-dark-theme: map.get($color-config, is-dark);

  $divider: mat.get-color-from-palette($foreground, divider);
  $outline-color: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.3, 0.12));
  $text: mat.get-color-from-palette($foreground, text);
  $secondary-text: mat.get-color-from-palette($foreground, secondary-text);

  app-emergency-contact-validator {
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: $background-color;

    width: 100%;
    height: 100%;

    font-size: 14px;

    .drawer-header {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .drawer-title {
        margin: 10px;
      }

      button {
        margin-right: 10px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 10px;

      .form-date-time {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 10px;
      }

      .form-separator {
        margin: 10px 0;
        border-bottom: 1px solid $divider;
      }

      .gaggle-radio {
        & > .mat-radio-button {
          &:not(:first-of-type) {
            margin-left: 20px;
          }
        }
      }

      .warning-type-display {
        display: flex;
        flex-flow: row nowrap;
        user-select: none;

        *:not(:first-child) {
          margin-left: 3px;
        }
      }

      .form-section-title {
        margin-top: 5px;
        margin-bottom: 5px;
        font-weight: 500;

        &.subtitle {
          font-weight: 500;
          margin-top: 10px;
        }
      }

      .content-category {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    .form-buttons {
      margin: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex: 1;
    }

    .content-container {
      width: 100%;
      height: 100%;
      overflow-y: hidden;

      .title-bar {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        width: 100%;
        height: 55px;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.2em;
        border-bottom: 1px solid $divider;

        .actions {
          padding-left: 30px;

          mat-icon {
            margin-top: -2px;
            transform: scale(0.8);
          }
        }

        .title-text {
          font-size: 18px;
          text-align: center;
        }
      }

      .gaft-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 10px;

        .gaft-indicator {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          padding: 0 20px;
          min-height: 40px;
          background-color: #1b1a30;
          color: white;
          font-size: 1.2em;
        }
      }

      .instructions {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
      }

      .contact-results {
        width: 100%;
        height: 100%;
        margin-top: 10px;

        .contact-button-toggle-container {
          width: 100%;
          height: 35px;
          text-align: center;
          padding-bottom: 15px;
          border-bottom: 1px solid $divider;
        }

        .emergency-contacts {
          height: calc(100% - 60px - 50px - 10px); // 60 for the title bar, 50 for the button toggle container, 10 for the margin
          margin-top: 10px;
          overflow-y: scroll;

          .categories-tooltip {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .title {
              font-size: 1.3em;
              font-weight: bold;
              margin-bottom: 2px;
            }
          }

          .contact-details {
            font-size: 0.8em;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            & > span {
              margin-top: 4px;
            }

            & > span:first-of-type {
              margin-top: 0;
            }
          }

          .contact {
            display: grid;
            width: calc(100% - 40px);
            grid-template-columns: minmax(20%, 1fr) 100px 225px 125px minmax(0, 1fr);
            grid-column-gap: 5px;
            align-items: center;
            margin-bottom: 10px;
            padding: 0 20px 10px 20px;
            border-bottom: 1px solid $divider;

            .main-content {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;

              .contact-name-title {
                display: flex;
                flex-direction: column;

                .name {
                  display: flex;
                  flex-direction: row;
                  font-size: 1.2em;
                  font-weight: 500;
                  align-items: center;
                }

                .title {
                  font-size: 1em;
                  color: $secondary-text;
                }
              }

              .contact-icons {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                mat-icon {
                  margin-left: 2px;
                  transform: scale(0.75);
                }
              }
            }

            gaggle-pill {
              & > button {
                max-height: 30px;
              }

              &.selected-availability {
                button {
                  border: 1px solid $secondary-text;
                }
              }

              .pill-details {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                font-size: 0.9em;
                font-weight: bold;
                padding: 2px;

                .pill-type {
                  color: $text;
                  margin-right: 3px;
                }

                .pill-separator {
                  margin: 0 3px;
                  font-size: 1em;
                  color: $outline-color;
                }

                mat-icon {
                  transform: scale(0.8);
                  color: $outline-color;

                  &.available {
                    color: $text;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
