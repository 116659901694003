@use "sass:map";
@use "@angular/material" as mat;

@import "src/variables";
@import "@angular/material/theming";

@mixin search-navigation-theme($theme) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $background-color: mat.get-color-from-palette($background, card);
  $text: mat.get-color-from-palette($foreground, text);
  $secondary-text: mat.get-color-from-palette($foreground, secondary-text);

  app-search-navigation {
    z-index: 100;

    .navigation-wrapper {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 10px;
      display: flex;
      flex-direction: column;
      margin-right: 2.5em;
      background-color: $background-color;
      border-radius: 4px;
      padding: 4px;

      .navigation-buttons {
        display: flex;
        flex-direction: row;
      }

      .label {
        margin-top: 2px;
        text-align: center;
        font-size: 0.8rem;
        color: $secondary-text;
      }

      button:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
}
