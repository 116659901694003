@use "sass:map";
@use "@angular/material" as mat;

@import "src/variables";
@import "@angular/material/theming";

@mixin district-school-guidance-theme($theme) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $background-color: mat.get-color-from-palette($background, "background");
  $card-background: mat.get-color-from-palette($background, "card");
  $divider: mat.get-color-from-palette($foreground, divider);

  app-district-school-guidance {
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: $background-color;

    width: 100%;
    height: 100%;

    .error-snackbar {
      background-color: red;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .header {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        border-bottom: 1px solid $divider;
        height: 55px;
        min-height: 55px;
        padding-left: 30px;
        align-items: center;

        button:not(:first-of-type) {
          margin-left: 10px;
        }

        mat-icon {
          margin-top: -2px;
          transform: scale(0.8);
        }

        .title-text {
          font-size: 18px;
          text-align: center;
        }
      }

      & > .content {
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        width: 100%;
        height: 100%;
        flex: 1;
      }
    }
  }
}
