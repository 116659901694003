@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
@import "variables";
@import "theme";
@import "mixins";
@import "constants";
@import "material";

html {
  margin: 0;
}

// TODO: This is temporary until we can get the big pill into ui-common
.big-pill {
  button {
    max-height: 40px !important;
  }

  gaggle-icon {
    & > div:first-of-type {
      height: 24px !important;
      width: 24px !important;
    }
  }

  p {
    font-size: 24px;
  }
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  height: 100%;
  background-color: var(--background-primary-color);
  color: var(--text-primary-color);
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 62.5%;
}

* {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 0;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track-piece,
  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-corner {
    background-color: inherit;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 10px;
    border: 5px solid var(--background-primary-color);
  }
}

.highlightText {
  color: var(--red-color) !important;
}

.required::after {
  content: "*" !important;
  color: var(--red-color) !important;
  vertical-align: super;
  padding: 0 1px;
}

// styles that are not applied correctly when they live in their component.scss file
.quarantine-menu {
  // from item-view-action-bar
  max-width: fit-content !important;
}

// apply theme colors to all mat menu and menu items
[role="menu"].mat-menu-panel {
  background-color: var(--background-primary-color);
  color: var(--text-primary-color);

  button.mat-menu-item {
    color: var(--text-primary-color);
  }
}

// apply theme colors to material icons
button > mat-icon {
  color: var(--text-primary-color) !important;
  margin: 0 0.5em !important;
}

.template-menu + * .cdk-overlay-pane > div.mat-menu-panel {
  // from email-content
  min-width: 37em;
  max-width: 37em;
  background-color: var(--background-primary-color);
  color: var(--text-primary-color);

  div.mat-menu-content {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 48px);
    border-radius: 4px;
    outline: 0;

    .menu-header {
      display: flex;
      justify-content: space-between;
      line-height: 18px;
      height: auto;
      font-size: 1.4em;
      font-weight: bold;
      padding: 0 1em 6px;
      text-align: left;
      text-decoration: none;
      max-width: 100%;
      border-bottom: 1px solid var(--border-color);

      .top-border {
        border-top: 1px solid var(--border-color);
      }
    }

    .other-menu-header {
      display: flex;
      justify-content: space-between;
      line-height: 18px;
      height: auto;
      font-size: 1.4em;
      font-weight: bold;
      padding: 6px 1em;
      text-align: left;
      text-decoration: none;
      max-width: 100%;
      border-top: 1px solid var(--border-color);
    }

    .show-more,
    .show-less {
      display: flex;
      color: var(--link-color);
      font-weight: bold;
      padding-top: 0.8em;
      padding-left: 1em;

      .more {
        font-size: 1.2em;
        padding-right: 0.2em;
        padding-bottom: 2px;
      }

      .less {
        font-size: 1.2em;
        padding-right: 0.1em;
        padding-bottom: 2px;
      }

      .text {
        font-size: 1.2em;
      }

      .more,
      .less,
      .text {
        &:hover {
          cursor: pointer;
        }
      }
    }

    button.mat-menu-item {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      outline: 0;
      border: none;
      -webkit-tap-highlight-color: transparent;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      line-height: 18px;
      height: auto;
      padding-top: 3px;
      padding-bottom: 3px;
      text-align: left;
      text-decoration: none;
      max-width: 100%;
      position: relative;
      color: var(--text-primary-color);
    }
  }
}

// apply styles to date range picker for search
mat-date-range-input {
  display: flex;
  align-items: unset;

  .mat-date-range-input-container {
    justify-content: space-evenly !important;

    .mat-date-range-input-start-wrapper,
    .mat-date-range-input-end-wrapper {
      overflow: visible !important;
      height: 30px; // same height as inputs

      .mat-date-range-input-inner {
        position: unset;
      }
    }
  }
}

mat-datepicker-toggle {
  button.mat-icon-button {
    color: var(--text-primary-color) !important;
  }
}

.vertical-separator {
  width: 1px;
  height: 2em;
  background-color: var(--mid-divider-color);
  margin: 0 2em;
}

// In order to allow for a multiline tooltip, we need to override the default white-space: nowrap
.mat-tooltip {
  white-space: pre-line;
  line-height: 1.5em;
}

// Add styling for gaggle-icon when wrapped in a mat-icon-button
.gaggle-icon-button {
  & > .mat-button-wrapper {
    & > gaggle-icon {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

// Override the mini-fab-button with a new MICRO fab (just a little smaller)
$button-size: 28px;
$font-size: 18px;
$line-height: 12px;
.gaggle-micro-fab-button {
  &.mat-mini-fab {
    height: $button-size;
    width: $button-size;
    min-width: $button-size;
    min-height: $button-size;
    padding: 0;

    mat-icon {
      font-size: $font-size;
      line-height: $line-height;
      margin-top: -2px;
    }
  }
}

// Squish the mat-form-field elements a bit with a custom class
.gaggle-form-field .mat-form-field-wrapper {
  padding-bottom: 0;
  margin: 10px 0 !important;

  .mat-form-field-infix {
    padding: 5px 0;
    min-width: 0;
    width: auto;
  }

  .mat-form-field-suffix {
    top: 50%;
  }

  .mat-form-field-suffix {
    .mat-datepicker-toggle {
      & > button {
        height: 24px;
        width: 24px;

        .mat-button-wrapper {
          svg {
            height: 18px;
            width: 18px;
            margin-bottom: -2px;
          }
        }
      }
    }
  }

  .mat-form-field-suffix .mat-icon {
    font-size: 18px;
    height: 18px;
    width: 18px;
  }

  .mat-input-element[type="time"] {
    &::-webkit-calendar-picker-indicator {
      display: none;
    }

    &::after {
      white-space: normal;
    }
  }
}

.gaggle-form-field .gaggle-date-picker .mat-datepicker-input {
  width: 85%;

  mat-icon {
    position: relative;
    float: right;
    top: -3px;
    cursor: pointer;
  }
}

// Squish the mat-stepper
.gaggle-stepper {
  .mat-horizontal-stepper-header {
    height: 45px;
  }

  .mat-horizontal-content-container {
    padding: 0;
  }
}

.gaggle-radio {
  .mat-radio-container {
    transform: scale(0.75);
  }
}

.error-snackbar {
  background-color: var(--error-color);
  color: var(--text-primary-color);
}

.gaggle-tree {
  .mat-tree-node {
    min-height: 30px;

    button {
      height: 30px;
      width: 30px;
      font-size: 24px;
      line-height: 24px;
    }
  }

  .mat-button-wrapper {
    .material-icons {
      font-size: 16px;
    }
  }
}

// Make the mat-checkbox just a little bigger
.gaggle-checkbox {
  .mat-checkbox-inner-container {
    height: 20px;
    width: 20px;
  }
}

.gaggle-short-button-toggle {
  .mat-button-toggle-label-content {
    line-height: 24px;
  }
}

// When putting a button inline with a form field, it looks weird if the height is different
// This makes the button the same height as the gaggle form field
button.gaggle-form-field-button {
  max-height: 33px;

  .mat-icon {
    font-size: 20px;
  }
}

// Override the first cell for mat-table to allow for the tab on the slide drawer
$extra_space_for_tab: 30px;
.mat-table-with-filter-drawer {
  &.mat-table {
    .mat-header-row {
      .mat-header-cell:first-of-type {
        padding-left: $extra_space_for_tab;
      }
    }
    .mat-row {
      .mat-cell:first-of-type {
        padding-left: $extra_space_for_tab;
      }
    }
    .mat-footer-row {
      .mat-footer-cell:first-of-type {
        padding-left: $extra_space_for_tab;
      }
    }
  }
}

// Add styling to make the page size selector smaller and not look like garbage
.gaggle-mat-paginator {
  .mat-paginator-page-size {
    align-items: center;

    .mat-paginator-page-size-select {
      margin: 0 4px;
      .mat-form-field-wrapper {
        padding-bottom: 0;
        margin: 10px 0 !important;

        .mat-form-field-infix {
          padding: 0 0 5px 0;
        }

        .mat-select-arrow-wrapper {
          margin-top: 7px;
        }
      }
    }
  }
}

.gaggle-full-height-tab-group {
  .mat-tab-body-wrapper {
    flex-grow: 1;
  }

  .mat-tab-body {
    display: flex;
    flex-direction: column;
  }

  .mat-tab-body-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

// Define global styles for the SnackbarService to use.
.mat-snack-bar-container {
  &.snackbar-service-error {
    background-color: var(--gaggle-qcon);
    font-weight: 500;
    color: var(--material-background-color);
    .mat-button {
      backdrop-filter: brightness(0.8);
      color: var(--material-background-color);
    }
  }
  &.snackbar-service-warning {
    background-color: var(--gaggle-qcon);
    font-weight: 500;
    color: var(--material-background-color);
    .mat-button {
      backdrop-filter: brightness(0.8);
      color: var(--material-background-color);
    }
  }
}
