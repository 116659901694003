@import "variables";

$colors: (
  --background-primary-color: (
    $LIGHT_background-primary-color,
    $DARK_background-primary-color,
  ),
  --background-secondary-color: (
    $LIGHT_background-secondary-color,
    $DARK_background-secondary-color,
  ),
  --background-special-notes-color: (
    $LIGHT_background-special-notes,
    $DARK_background-special-notes,
  ),
  --text-title-color: (
    $LIGHT_text-color-dark,
    $DARK_text-color-lighter,
  ),
  --text-primary-color: (
    $LIGHT_text-color,
    $DARK_text-color,
  ),
  --text-secondary-color: (
    $LIGHT_text-color-light,
    $DARK_text-color-dark,
  ),
  --text-disabled-color: (
    $LIGHT_text-color-lighter,
    $DARK_text-color-darker,
  ),
  --button-secondary-color: (
    $LIGHT_secondary-button,
    $DARK_secondary-button,
  ),
  --link-color: (
    $LIGHT_link-color,
    $DARK_link-color,
  ),
  --link-hover-color: (
    $LIGHT_link-hover-color,
    $DARK_link-hover-color,
  ),
  --nav-link-color: (
    $LIGHT_navigation-link-color,
    $DARK_navigation-link-color,
  ),
  --red-color: (
    $LIGHT_red,
    $DARK_red,
  ),
  --green-color: (
    $LIGHT_green,
    $DARK_green,
  ),
  --blue-color: (
    $LIGHT_blue,
    $DARK_blue,
  ),
  --scrollbar-color: (
    $LIGHT_text-color-lighter,
    $DARK_text-color-darker,
  ),
  --divider-color: (
    $LIGHT_divider-color,
    $DARK_divider-color,
  ),
  --mid-divider-color: (
    $LIGHT_mid-divider-color,
    $DARK_mid-divider-color,
  ),
  --border-color: (
    $LIGHT_border-color,
    $DARK_border-color,
  ),
  --border-secondary-color: (
    $LIGHT_border_secondary-color,
    $DARK_border-secondary-color,
  ),
  --border-lightest-color: (
    $LIGHT_border-lightest-color,
    $DARK_border-lightest-color,
  ),
  --item-detail-hover-color: (
    $LIGHT_detail-hover-color,
    $DARK_detail-hover-color,
  ),
  --box-shadow: (
    $LIGHT_box-shadow,
    $DARK_box-shadow,
  ),
  --toggle-box-shadow: (
    $LIGHT_toggle-box-shadow,
    $DARK_toggle-box-shadow,
  ),
  --left-drawer-box-shadow: (
    $LIGHT_left-drawer-box-shadow,
    $DARK_left-drawer-box-shadow,
  ),
  --right-drawer-box-shadow: (
    $LIGHT_right-drawer-box-shadow,
    $DARK_right-drawer-box-shadow,
  ),
  --toggle-dot-pss: (
    $LIGHT_toggle-dot-pss,
    $DARK_toggle-dot-pss,
  ),
  --toggle-capsule-pss: (
    $LIGHT_toggle-capsule-pss,
    $DARK_toggle-capsule-pss,
  ),
  --toggle-dot-qcon: (
    $LIGHT_toggle-dot-qcon,
    $DARK_toggle-dot-qcon,
  ),
  --toggle-capsule-qcon: (
    $LIGHT_toggle-capsule-qcon,
    $DARK_toggle-capsule-qcon,
  ),
  --toggle-dot-center: (
    $LIGHT_toggle-dot-center,
    $DARK_toggle-dot-center,
  ),
  --toggle-capsule-center: (
    $LIGHT_toggle-capsule-center,
    $DARK_toggle-capsule-center,
  ),
  --checkbox: (
    $LIGHT_checkbox,
    $DARK_checkbox,
  ),
  --chat-background: (
    $LIGHT_chat-background,
    $DARK_chat-background,
  ),
  --chat-background-flagged: (
    $LIGHT_chat-background-flagged,
    $DARK_chat-background-flagged,
  ),
  --chat-highlight: (
    black,
    white,
  ),
  --gaggle-purple: (
    $LIGHT_gaggle-purple,
    $DARK_gaggle-purple,
  ),
  --gaggle-orange: (
    $LIGHT_gaggle-orange,
    $DARK_gaggle-orange,
  ),
  --gaggle-pss: (
    $LIGHT_gaggle-pss,
    $DARK_gaggle-pss,
  ),
  --gaggle-qcon: (
    $LIGHT_gaggle-qcon,
    $DARK_gaggle-qcon,
  ),
  --gaggle-viol: (
    $LIGHT_gaggle-viol,
    $DARK_gaggle-viol,
  ),
  --gaggle-resolved: (
    $LIGHT_gaggle-resolved,
    $DARK_gaggle-resolved,
  ),
  --menu-button-background: (
    $LIGHT_menu-button-background,
    $DARK_menu-button-background,
  ),
  --background-search-color: (
    $LIGHT_background-search,
    $DARK_background-search,
  ),
  --dialog-background-color: (
    $LIGHT_background-dialog-color,
    $DARK_background-dialog-color,
  ),
  --search-header-icon-color: (
    $LIGHT_search-header-icon,
    $DARK_search-header-icon,
  ),
  --chat-hover-menu: (
    rgba(0, 0, 0, 0.15),
    rgba(255, 255, 255, 0.5),
  ),
  --calendar-highlight: (
    $SHARED_calendar,
    $SHARED_calendar,
  ),
  --canvas-highlight: (
    $SHARED_canvas,
    $SHARED_canvas,
  ),
  --file-highlight: (
    $SHARED_file,
    $SHARED_file,
  ),
  --file_comment-highlight: (
    $SHARED_file_comment,
    $SHARED_file_comment,
  ),
  --hangout-highlight: (
    $SHARED_hangout,
    $SHARED_hangout,
  ),
  --message-highlight: (
    $SHARED_message,
    $SHARED_message,
  ),
  --speakup-highlight: (
    $SHARED_speakup,
    $SHARED_speakup,
  ),
  --webfilter-highlight: (
    $SHARED_webfilter,
    $SHARED_webfilter,
  ),
  --yammer-highlight: (
    $SHARED_yammer,
    $SHARED_yammer,
  ),
  --pill-highlight-text-color: (
    black,
    black,
  ),
  --search-background-color: (
    rgba(0, 0, 0, 0.05),
    $DARK_background-secondary-color,
  ),
);

.theme-light {
  @each $name, $color in $colors {
    #{$name}: nth($color, 1);
  }
}

.theme-dark {
  @each $name, $color in $colors {
    #{$name}: nth($color, 2);
  }
}
