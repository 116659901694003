@use "sass:map";
@use "@angular/material" as mat;

@import "@angular/material/theming";

@mixin search-form-theme($theme) {
  app-search-form {
    font-size: 14px;

    .drawer-header-title {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .mat-icon {
        transform: scale(0.75);
      }
    }

    form {
      height: calc(100% - 20px);
      display: flex;
      flex-direction: column;
      margin: 10px 10px 0;
    }

    .user-search {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      margin-left: 5px;

      .label {
        font-size: 0.9rem;
      }

      .mat-radio-button:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}
