@use "sass:map";
@use "@angular/material" as mat;

@import "material-variables";
@import "src/app/components/item-viewer/file-comment-panel/file-comment-panel.component";
@import "src/app/shared/modules/slide-drawer/slide-drawer.component";
@import "src/app/shared/modules/district-group-search/district-group-search-input/district-group-search-input.component";
@import "src/app/shared/modules/district-group-search/district-group-search-dialog/district-group-search-dialog.component";
@import "src/app/features/admin/components/admin.component";
@import "src/app/features/admin/components/admin-left-sidebar/admin-left-sidebar.component";
@import "src/app/features/admin/components/district-school-guidance/district-school-guidance.component";
@import "src/app/features/admin/components/district-school-guidance/district-school-guidance-form/district-school-guidance-form.component";
@import "src/app/features/admin/components/district-school-guidance/district-school-guidance-list/district-school-guidance-list.component";
@import "src/app/features/admin/components/emergency-contact-validator/emergency-contact-validator.component";
@import "src/app/features/admin/components/audit/audit.component";
@import "src/app/features/search/components/search.component";
@import "src/app/features/search/components/search-results/search-results.component";
@import "src/app/features/search/components/search-form/search-form.component";
@import "src/app/components/incident-summary/incident-contact-communications-dialog/incident-contact-communications-dialog.component";
@import "src/app/shared/components/send-sms-dialog/send-sms-dialog.component";
@import "src/app/shared/components/date-time-range/date-time-range.component";
@import "src/app/shared/components/checkbox-group/checkbox-group.component";
@import "src/app/shared/components/search-navigation/search-navigation.component";

@include mat.core();

// Only mixin the components we're using to keep from adding
// a ton of unused styles to the application
@mixin material-components-theme($theme) {
  @include mat.autocomplete-theme($theme);
  @include mat.button-theme($theme);
  @include mat.button-toggle-theme($theme);
  @include mat.card-theme($theme);
  @include mat.checkbox-theme($theme);
  @include mat.core-theme($theme);
  @include mat.datepicker-theme($theme);
  @include mat.dialog-theme($theme);
  @include mat.form-field-theme($theme);
  @include mat.icon-theme($theme);
  @include mat.input-theme($theme);
  @include mat.list-theme($theme);
  @include mat.menu-theme($theme);
  @include mat.paginator-theme($theme);
  @include mat.progress-bar-theme($theme);
  @include mat.progress-spinner-theme($theme);
  @include mat.radio-theme($theme);
  @include mat.select-theme($theme);
  @include mat.sidenav-theme($theme);
  @include mat.snack-bar-theme($theme);
  @include mat.sort-theme($theme);
  @include mat.stepper-theme($theme);
  @include mat.tabs-theme($theme);
  @include mat.table-theme($theme);
  @include mat.toolbar-theme($theme);
  @include mat.tooltip-theme($theme);
  @include mat.tree-theme($theme);
}

// Mixin the themes for our custom components here
@mixin app-components-theme($theme) {
  @include admin-left-sidebar-theme($theme);
  @include admin-theme($theme);
  @include audit-theme($theme);
  @include checkbox-group-theme($theme);
  @include date-time-range-theme($theme);
  @include district-group-search-dialog-theme($theme);
  @include district-group-search-input-theme($theme);
  @include district-school-guidance-form-theme($theme);
  @include district-school-guidance-list-theme($theme);
  @include district-school-guidance-theme($theme);
  @include emergency-contact-validator-theme($theme);
  @include file-comment-panel-theme($theme);
  @include incident-contact-communications-dialog-theme($theme);
  @include search-form-theme($theme);
  @include search-navigation-theme($theme);
  @include search-results-theme($theme);
  @include search-theme($theme);
  @include send-sms-dialog-theme($theme);
  @include slide-drawer-theme($theme);
}

$gaggle-purple-palette: (
  50: #e4e4e7,
  100: #bcbbc2,
  200: #8f8e9a,
  300: #626172,
  400: #413f53,
  500: #1f1d35,
  600: #1b1a30,
  700: #171528,
  800: #121122,
  900: #0a0a16,
  A100: #5858ff,
  A200: #2525ff,
  A400: #0000f1,
  A700: #0000d8,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$gaggle-orange-palette: (
  /* For use in src/lib/core/theming/_palette.scss */ 50: #feeee7,
  100: #fdd4c4,
  200: #fcb89c,
  300: #fb9c74,
  400: #fa8657,
  500: #f97139,
  600: #f86933,
  700: #f75e2c,
  800: #f65424,
  900: #f54217,
  A100: #ffffff,
  A200: #fff4f2,
  A400: #ffcabf,
  A700: #ffb4a6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  )
);

$primary: mat.define-palette($gaggle-purple-palette);
$accent: mat.define-palette($gaggle-orange-palette);
$warn: mat.define-palette(mat.$red-palette);

$light-theme: mat.define-light-theme($primary, $accent, $warn);
.light-theme {
  @include material-components-theme($light-theme);
  @include app-components-theme($light-theme);
  @include material-variables-theme($light-theme);
}

$primary: mat.define-palette($gaggle-purple-palette, 200, 100, 300);
$dark-theme: mat.define-dark-theme($primary, $accent, $warn);
.dark-theme {
  @include material-components-theme($dark-theme);
  @include app-components-theme($dark-theme);
  @include material-variables-theme($dark-theme);
}
