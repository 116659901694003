@use "sass:map";
@use "@angular/material" as mat;

@import "src/variables";
@import "@angular/material/theming";

@mixin district-school-guidance-form-theme($theme) {
  // Get color and typography config from theme
  $color-config: mat.get-color-config($theme);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark-theme: map.get($color-config, is-dark);

  $outline-color: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.3, 0.12));
  $input-background-color: mat.get-color-from-palette($background, input);
  $secondary-text: mat.get-color-from-palette($foreground, secondary-text);

  // This cannot be nested inside of the app-district-school-guidance-form selector
  // as the dialog is rendered outside of the component
  .app-district-school-guidance-legacy-notes-dialog {
    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .notes-list {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      width: calc(100% - 2px);
      border: 1px solid $outline-color;
      font-size: 12px;

      & > span {
        flex: 1;
        background-color: $input-background-color;
        margin: 2px 10px;

        &:first-of-type {
          margin-top: 10px;
        }

        &:last-of-type {
          margin-bottom: 10px;
        }
      }
    }
  }

  app-district-school-guidance-form {
    font-size: 14px;

    .mat-dialog-title {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;

      .title {
        font-size: 18px;
        line-height: 24px;
      }

      .subtitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .district-school-display {
          font-size: 12px;
          display: flex;
          flex-direction: column;
          font-weight: normal;
          min-height: 36px;

          & > div {
            height: 18px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
          }

          .type-indicator {
            margin-right: 5px;
            font-size: 0.8em;
            padding: 2px 4px;
            border: 1px solid $outline-color;
            border-radius: 5px;
            line-height: 1em;
            width: 3.4em;
            text-align: center;
          }
        }
      }
    }

    .mat-dialog-content {
      min-height: 325px;
    }

    .stepper-form {
      margin: 25px;
      flex: 1;
      display: flex;
      flex-direction: column;

      mat-form-field {
        margin-top: 10px;
      }

      .fieldset-title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;

        .optional {
          font-size: 0.8em;
          color: $secondary-text;
          margin-left: 3px;
        }

        &:first-of-type {
          margin-top: 0;
        }
      }

      .date-fields {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) 30px;
        align-items: center;

        mat-form-field {
          margin-left: 5px;
        }

        mat-form-field:first-of-type {
          margin-right: 5px;
          margin-left: 0;
        }

        .mat-icon-button {
          margin-top: 8px;
          .mat-icon {
            line-height: 18px;
            font-size: 18px;
            height: 18px;
            width: 18px;
          }
        }
      }
    }

    .mat-dialog-actions {
      .button-spacer {
        flex: 1;
      }

      button {
        width: 90px;
      }
    }
  }
}
