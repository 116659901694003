@use "sass:map";
@use "@angular/material" as mat;

@import "src/variables";
@import "@angular/material/theming";

@mixin district-group-search-input-theme($theme) {
  // Get color and typography config from theme
  $color-config: mat.get-color-config($theme);

  // Get typography values from config
  // TODO: Figure out how to use the typography mixin
  $font-size: 14px;
  $line-height: 16px;
  $font-weight: 400;

  // Grab relevant palettes from theme
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark-theme: map.get($color-config, is-dark);

  // Extract different colors for use in component
  $background-color: mat.get-color-from-palette($background, background);
  $divider: mat.get-color-from-palette($foreground, divider);
  $text: mat.get-color-from-palette($foreground, text);
  $secondary-text: mat.get-color-from-palette($foreground, secondary-text);
  $input-background: mat.get-color-from-palette($background, card);
  $outline-color: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.3, 0.12));
  $outline-color-hover: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 1, 0.87));
  $disabled: mat.get-color-from-palette($foreground, disabled);
  $disabled-text: mat.get-color-from-palette($foreground, disabled-text);

  // Reusable variables for this component
  $border-radius: 4px;
  $nested-radius: $border-radius - 1px;

  // Mixins break encapsulation, so we need to scope all of these styles to the component
  app-district-group-search-input {
    margin-top: 10px;

    .wrapper {
      &.disabled {
        cursor: not-allowed;
      }

      font-size: $font-size;
      line-height: $line-height;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 10px;
    }

    .label {
      font-size: 0.8em;
      color: $secondary-text;
      margin-bottom: -10px;

      &.disabled {
        color: $disabled-text;
      }

      & > .label-text {
        z-index: 0;
        position: relative;
        background-color: $input-background;
        padding: 0 4px;
        margin-left: 6px;
      }
    }

    .faux-input {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      background-color: transparent;
      border-radius: $border-radius;
      border: 1px solid $outline-color;
      color: $text;
      width: 100%;
      cursor: text;

      &.disabled {
        cursor: not-allowed;
        color: $disabled-text;

        &:hover {
          border-color: $outline-color;
        }
      }

      &:hover {
        border-color: $outline-color-hover;
      }

      .selection {
        min-height: 1.25em;
        padding-top: 5px;
        margin: 5px 5px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        max-width: 100%;
        overflow: hidden;

        & > .district {
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        & > .group {
          display: flex;
          flex-direction: row;
          max-width: 100%;

          & > .thingy {
            $border-color: lighten($secondary-text, 50%);

            &.disabled {
              $border-color: $disabled-text;
            }

            border-radius: 0 0 0 2px;
            border-left: 2px solid $border-color;
            border-bottom: 2px solid $border-color;
            width: 8px;
            margin: 0 4px calc($font-size / 2) 7px;
          }

          & > .group-name {
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .input-controls {
        display: flex;
        flex-direction: row;
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;
        }

        .icon-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        & > .clear-input {
          &.icon-wrapper {
            border-left: 1px solid $divider;
            &:hover {
              background-color: darken($outline-color, 50%);
            }
          }

          &.disabled {
            background-color: darken($outline-color, 50%);

            &:hover {
              cursor: not-allowed;
              background-color: darken($outline-color, 50%);

              mat-icon {
                color: $disabled-text;
              }
            }

            mat-icon {
              color: $disabled-text;
            }
          }

          mat-icon {
            transform: scale(0.75);
            color: $text;
          }
        }
      }

      &:disabled {
        color: $disabled-text;
        background-color: $disabled;

        &:hover {
          cursor: not-allowed;
        }
      }
    }

    input {
      height: 0;
      visibility: hidden;
      box-sizing: border-box;
    }
  }
}
