@use "sass:map";
@use "@angular/material" as mat;

@import "@angular/material/theming";

@mixin search-results-theme($theme) {
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);
  $divider: mat.get-color-from-palette($foreground, divider);
  $hover: mat.get-color-from-palette($background, hover);

  app-search-results {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .mat-table {
      flex: 1;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        margin-top: 55px;
      }

      .mat-header-row {
      }

      .results-column {
        padding: 0 10px;
        max-width: calc(100% - 20px);
      }

      .shared-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .primary-value {
        }

        .secondary-value {
          font-size: 0.7em;
        }
      }
    }

    .mat-row {
      cursor: pointer;
      position: relative;

      &:hover {
        background-color: $hover;
      }
    }

    .mat-paginator {
      border-top: 1px solid $divider;
    }

    // Action Column Stuff
    .mat-header-cell.action-column {
      display: none;
    }

    .mat-row {
      &:hover {
        & > .action-column {
          div.overlay {
            display: block;
          }
        }
      }

      & > .action-column {
        $button-size: 35px;

        div.overlay {
          flex: 1 1 100%;
          background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, $hover 10%, $hover 47%, $hover 100%);
          display: none;

          .mat-icon-button {
            width: $button-size;
            height: $button-size;
            line-height: $button-size;
            margin-top: 7px;
            margin-bottom: 6px;
          }

          button {
            margin: 4px 0;
            color: mat.get-color-from-palette($foreground, icon);
          }
        }
      }
    }

    .mat-cell.pre-wrap {
      white-space: pre-wrap;
    }

    .mat-cell.action-column {
      $card-background: mat.get-color-from-palette($background, card);
      background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, $card-background 10%, $card-background 47%, $card-background 100%);

      flex: 1 1 100%;
      position: absolute;
      right: 0;
      padding-right: 0;
    }
  }
}
