@use "sass:map";
@use "@angular/material" as mat;

@import "@angular/material/theming";

@mixin checkbox-group-theme($theme) {
  app-checkbox-group {
    .mat-expansion-panel-header {
      padding: 0;
      font-size: inherit;

      .mat-expansion-indicator {
        margin-right: 5px;
      }
    }

    .mat-expansion-panel-body {
      padding: 0;
    }

    .checkbox-group {
      margin-bottom: 12px;

      .checkbox-options {
        display: flex;
        flex-direction: column;
        margin-left: 1.5rem;
      }
    }
  }
}
