@use "sass:map";
@use "@angular/material" as mat;

@import "src/variables";
@import "@angular/material/theming";

@mixin audit-theme($theme) {
  $foreground: map.get($theme, foreground);
  $divider: mat.get-color-from-palette($foreground, divider);

  app-audit {
    .title-bar {
      display: flex;
      height: 55px;
      align-items: center;
      justify-content: center;
      font-size: 1.2em;
      border-bottom: 1px solid $divider;

      .title-text {
        font-size: 18px;
        text-align: center;
      }
    }

    .view-item-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: calc(100% - 56px);
      font-size: 10px;

      .item-id {
        font-weight: bold;
      }

      .middle-pane,
      .side-pane {
        height: 100%;
        overflow: auto;
      }

      .middle-pane {
        margin: 0;
        padding: 0;
        flex: 1.5;
      }

      .side-pane {
        max-width: 56em;
        background-color: var(--background-secondary-color);
        z-index: 2;
        flex: 1;

        &.incident-response {
          padding: 0 3em;
          box-shadow: var(--right-drawer-box-shadow);

          .drawer-header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 2em;

            .drawer-header {
              font-size: 2em;
              color: var(--text-title-color);
            }

            .cancel {
              color: var(--text-secondary-color);
              font-size: 1.5em;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }

        &.item-details {
          box-shadow: var(--left-drawer-box-shadow);

          .audit-banner {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin: 2em;

            & > gaggle-button {
            }

            & > .divider {
              width: 1px;
              height: 2.5em;
              background-color: var(--border-secondary-color);
              margin: 0 2em;
            }

            & > .text {
              font-size: 2em;
              font-weight: bold;
              width: 100%;
            }
          }
        }
      }
    }

    .button-container {
      margin: 2em auto;
      width: 95%;
    }

    .not-found-message {
      font-size: 1em;
      margin: 5em auto;
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;

      .buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 25px;
      }
    }
  }
}
