@use "sass:map";
@use "@angular/material" as mat;

@import "src/variables";
@import "@angular/material/theming";

@mixin send-sms-dialog-theme($theme) {
  // Get color and typography config from theme
  $color-config: mat.get-color-config($theme);

  // Get typography values from config
  // TODO: Figure out how to use the typography mixin
  $font-size: 14px;
  $line-height: 16px;

  // Grab relevant palettes from theme
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark-theme: map.get($color-config, is-dark);

  $divider: mat.get-color-from-palette($foreground, divider);
  $hover: mat.get-color-from-palette($background, hover);
  $outline-color: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.3, 0.12));
  $text: mat.get-color-from-palette($foreground, text);
  $secondary-text: mat.get-color-from-palette($foreground, secondary-text);

  app-send-sms-dialog {
    font-size: 14px;

    .send-sms-dialog .mat-dialog-content {
      min-width: 400px;
      max-width: 400px;
      display: flex;
      flex-flow: column;
    }

    .title {
      padding: 5px 0;
      margin-bottom: 5px;
      border-bottom: 1px solid $divider;
    }

    .full-width-select {
      width: 100%;
    }

    .sms-content {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }

    .recipients {
      display: flex;
      flex-direction: column;

      .recipient {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 5px;
        margin-bottom: 5px;

        .name {
          text-align: left;
        }

        .phone {
          text-align: center;
        }

        .status {
          text-align: right;
        }
      }
    }

    mat-dialog-actions {
      .send-button-container {
        display: flex;
        flex-flow: row;
        align-items: center;

        & > button {
          margin-right: 10px;
        }
      }

      justify-content: space-between;
    }
  }
}
