@use "sass:map";
@use "@angular/material" as mat;

@import "src/variables";
@import "@angular/material/theming";

@mixin incident-contact-communications-dialog-theme($theme) {
  // Get color and typography config from theme
  $color-config: mat.get-color-config($theme);

  // Get typography values from config
  // TODO: Figure out how to use the typography mixin
  $font-size: 14px;
  $line-height: 16px;

  // Grab relevant palettes from theme
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark-theme: map.get($color-config, is-dark);

  $divider: mat.get-color-from-palette($foreground, divider);
  $hover: mat.get-color-from-palette($background, hover);
  $outline-color: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.3, 0.12));
  $text: mat.get-color-from-palette($foreground, text);
  $secondary-text: mat.get-color-from-palette($foreground, secondary-text);

  app-incident-contact-communications-dialog {
    .loading-indicator {
      width: 100%;
      min-height: 100px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;

      .text {
        margin-top: 10px;
        font-size: 14px;
      }
    }

    .unavailable {
      font-size: $font-size;
    }

    .flex-spacer {
      flex: 1 1 auto;
    }

    .no-contacts {
      width: 100%;
      text-align: center;
      min-height: 50px;

      div {
        font-size: 1.2em;
        font-weight: bold;
        color: $secondary-text;
        padding: 10px;
        margin-top: auto;
      }
    }

    .contact-grid {
      display: flex;
      flex-direction: column;
      font-size: $font-size;

      .grid-row {
        display: grid;
        grid-template-columns: 20px minmax(0, 1fr) repeat(4, 40px);
        gap: 10px;
        border-bottom: 1px solid $divider;
        align-items: flex-start;
        padding: 5px 15px;

        &.header {
          font-weight: bold;
          padding-bottom: 10px;
          align-items: flex-end;

          div:last-of-type {
            display: grid;
            grid-column: 4 / -1; // Span remaining columns
            justify-items: center;
            align-items: center;

            .label {
              font-size: 0.8em;
              margin-bottom: 5px;
            }
          }
        }

        &:not(.header):hover {
          background-color: $hover;
        }

        .contact-details {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          margin-top: 24px;
          margin-left: -8px;

          & > .clickable {
            cursor: pointer;
          }

          & > .name {
          }

          & > .detail-line {
            display: flex;
            align-items: center;
            font-size: 0.9em;
            color: $secondary-text;
            padding: 2px 0;
            align-self: stretch;

            mat-icon {
              font-size: 16px;
              height: auto;
              width: auto;
              color: $secondary-text;
            }

            @keyframes slideInX {
              0% {
                transform: translateX(50%);
                opacity: 0;
              }

              50% {
                transform: translateX(25%);
                opacity: 0;
              }

              100% {
                transform: translateX(0%);
                opacity: 1;
              }
            }

            .show-on-hover {
              opacity: 0;
              transition: all 0.8s;
              animation: slideInX 0.8s;
              display: none;
            }

            &:hover {
              .show-on-hover {
                opacity: 1;
                display: flex;
                margin-left: 5px;
                font-size: 0.85em;
                padding: 1px 5px;
                background-color: $outline-color;
                border-radius: 5px;
                justify-content: center;
                align-items: center;

                mat-icon {
                  font-size: 1em;
                  margin-right: 3px;
                }
              }
            }
          }
        }

        .ack-cell {
          border-right: 1px solid $divider;
        }

        .availability {
          display: flex;
          flex-direction: column;
          align-self: stretch;
          align-items: center;
          justify-content: center;
          min-height: 35px;
          padding: 5px 0;

          mat-icon {
            color: $outline-color;

            &.acknowledged {
              color: green;
            }

            &.available {
              color: $text;
            }
          }

          .text {
            font-size: 0.65em;
            line-height: 1.1em;
            min-height: 1.1em;
          }
        }
      }
    }
  }
}
