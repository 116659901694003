@use "sass:map";
@use "@angular/material" as mat;

@import "src/variables";
@import "@angular/material/theming";

:host {
  margin-top: 15px;
  font-size: small;
}

@mixin file-comment-panel-theme($theme) {
  app-file-comment-panel {
    $dot-size: 0.8em;

    // MAT-HACK: Remove strong arm styling
    mat-card.mat-card {
      background: var(--background-primary-color);
      box-shadow: var(--box-shadow);
    }

    .section-title {
      color: var(--text-secondary-color);
      margin-bottom: 15px;
    }

    .author {
      display: flex;
      flex-direction: row;
      align-items: center;

      & > .user-dot {
        border-radius: 50%;
        height: $dot-size;
        width: $dot-size;
        margin: 0 0.5em 0 0;
      }
    }

    .comment {
      &.reply {
        border-left: 1px solid var(--border-color);
        margin-left: 20px;
        padding-left: 20px;
      }

      &:hover {
        & > .details {
          color: var(--text-primary-color);
        }
      }

      & > .details {
        padding-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--text-secondary-color);

        .spacer {
          flex-grow: 1;
        }
      }

      & > .content {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        & > .text {
          padding: 10px;
          margin: 5px 0;
          border-radius: 5px;
          color: var(--text-primary-color);
          background: var(--chat-background);

          &.flagged {
            background-color: var(--chat-background-flagged);
          }
        }

        &:hover {
          .translate {
            display: flex;
          }
        }

        .translate {
          &.translated {
            display: flex;
          }

          display: none;
          margin-left: 10px;
          flex-direction: row;
          align-items: center;

          gaggle-icon {
            color: var(--blue-color);
            cursor: pointer;

            &.translated {
              color: var(--text-disabled-color);
              cursor: not-allowed;
            }
          }

          .translated-text {
            margin-left: 10px;
            padding: 10px;
            border-radius: 5px;
            color: var(--text-primary-color);
            background: var(--background-special-notes-color);
          }
        }
      }
    }

    .participants {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      color: var(--text-secondary-color);

      & > .divider {
        border-top: 1px solid var(--border-color);
        height: 1em;
        width: 4em;
        margin-right: auto;
      }

      & > .list {
        margin: 0.5em 0;
      }
    }
  }
}
