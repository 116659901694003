@use "sass:map";
@use "@angular/material" as mat;

@import "src/variables";
@import "@angular/material/theming";

:host {
  max-width: var(--app-slide-drawer-max-width);
  min-width: var(--app-slide-drawer-min-width);
  position: relative;
  height: 100%;
  z-index: 200;
}

@mixin slide-drawer-theme($theme) {
  // Get color and typography config from theme
  $color-config: mat.get-color-config($theme);

  // Get typography values from config
  // TODO: Figure out how to use the typography mixin
  $font-size: 12px;
  $line-height: 16px;
  $font-weight: 400;

  // Grab relevant palettes from theme
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark-theme: map.get($color-config, is-dark);

  $background-color: mat.get-color-from-palette($background, card);
  $divider: mat.get-color-from-palette($foreground, divider);
  $elevation: mat.get-color-from-palette($foreground, elevation);

  $box-shadow: 1px 0 1px rgba(0, 0, 0, 0.14), 2px 0 2px rgba(0, 0, 0, 0.04), 4px 0 4px rgba(0, 0, 0, 0.04), 4px 0 8px rgba(0, 0, 0, 0.02);

  app-slide-drawer {
    display: block;
    z-index: 200;

    .drawer {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin: 0;
      border-right: 1px solid $divider;
      background-color: $background-color;

      .drawer-header {
        height: 56px;
        min-height: 56px;
        border-bottom: solid 1px $divider;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;

        .drawer-header-title {
          font-size: 14px;
          padding: 0 15px;
        }
      }

      .drawer-footer {
        display: flex;
        flex-flow: row nowrap;
        margin-top: auto;
        border-top: solid 1px $divider;
        min-height: 56px;

        .drawer-footer-buttons {
          padding: 5px 15px;
          display: flex;
          width: 100%;
          flex-flow: row nowrap;
          align-items: center;

          &.align-end {
            justify-content: flex-end;
          }

          &.align-start {
            justify-content: flex-start;
          }

          &.space-between {
            justify-content: space-between;
          }
        }
      }

      .drawer-content {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    .drawer-toggle {
      position: relative;
      float: right;
      left: 25px;
      margin-left: -26px;
      top: 20px;
      height: 45px;
      transform: perspective(3px) rotateY(5deg) translateX(2px);
      background-color: $background-color;
      border: solid 1px $divider;
      border-left: none;
      outline: none;
      box-shadow: 3px -3px 3px 0 darken($background-color, 20%);

      > div {
        display: flex;
        align-items: center;
        transform: perspective(3px) rotateY(-5deg) translateX(2px);
        padding-right: 3px;
        margin-left: -3px;
      }

      &:hover {
        cursor: pointer;
      }

      .drawer-dot {
        height: 0.5em;
        width: 0.5em;
        border-radius: 50%;
        background-color: var(--checkbox);
        visibility: hidden;
        margin-left: -8px;
        margin-right: 6px;
        margin-top: 6px;

        &.show-dot {
          visibility: visible;
        }
      }
    }
  }
}
