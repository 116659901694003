@use "sass:map";
@use "@angular/material" as mat;

@import "src/variables";
@import "@angular/material/theming";

@mixin admin-theme($theme) {
  $background: map.get($theme, background);
  $background-color: mat.get-color-from-palette($background, background);

  app-admin {
    .main-outlet {
      background-color: $background-color;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      height: 100%;
      width: 100%;
      font-size: 14px;
    }
  }
}
