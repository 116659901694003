@use "sass:map";
@use "@angular/material" as mat;
@import "@angular/material/theming";

@mixin material-variables-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $is-dark-theme: map.get($color-config, is-dark);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);

  $background-color: mat.get-color-from-palette($background, "background");
  $theme-class: if($is-dark-theme, "dark-theme", "light-theme");

  --material-background-color: #{$background-color};
  --material-card-background-color: #{mat.get-color-from-palette($background, "card")};
  --material-divider-color: #{mat.get-color-from-palette($foreground, divider)};
  --material-hover-color: #{mat.get-color-from-palette($background, hover)};
  --material-hover-outline-color: #{mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 1, 0.87))};
  --material-outline-color: #{mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.3, 0.12))};
  --material-secondary-text-color: #{mat.get-color-from-palette($foreground, secondary-text)};
  --material-text-color: #{mat.get-color-from-palette($foreground, text)};
  --material-void-background-color: #{darken($background-color, 3%)};
  --material-button-primary-background-color: #{mat.get-color-from-palette($background, "button-primary")};
  --material-warn-color: #{mat.get-color-from-palette($warn, default)};
}
