@use "sass:map";
@use "@angular/material" as mat;

@import "src/variables";
@import "@angular/material/theming";

@mixin district-school-guidance-list-theme($theme) {
  // Get color and typography config from theme
  $color-config: mat.get-color-config($theme);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark-theme: map.get($color-config, is-dark);

  $text: mat.get-color-from-palette($foreground, text);
  $hover: mat.get-color-from-palette($background, hover);
  $background-color: mat.get-color-from-palette($background, "background");
  $outline-color: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.3, 0.12));
  $secondary-text: mat.get-color-from-palette($foreground, secondary-text);

  app-district-school-guidance-list {
    flex: 1;

    .mat-tree {
      background-color: $background-color;
    }

    .district-title {
      border-bottom: 1px solid lighten($outline-color, 50%);
      padding: 15px;

      .district-name {
        font-size: 18px;
        font-weight: 500;
      }

      .guidance-detail {
        .detail-item {
          margin-right: 10px;
        }
      }
    }

    .guidance-tree-node {
      border-bottom: 1px solid darken($outline-color, 50%);

      cursor: pointer;
      user-select: none;
      &:hover {
        background-color: $hover;
      }

      .parent-node {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .type-indicator {
          margin-right: 10px;
          font-size: 0.8em;
          padding: 2px 4px;
          border: 1px solid $outline-color;
          border-radius: 5px;
          line-height: 1.1em;
          min-width: 4em;
          text-align: center;
        }

        .bold-name {
          font-weight: 500;
        }

        .child-count {
          margin-left: 5px;
          font-size: 0.8em;
          color: $secondary-text;
        }
      }

      .guidance-node {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 5px 0;

        .node-name {
          width: 40%;
        }

        .start-stop {
          margin-left: 10px;
          font-size: 0.9em;
          color: $secondary-text;
          width: 25%;

          .label {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
