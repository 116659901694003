@use "sass:map";
@use "@angular/material" as mat;

@import "@angular/material/theming";

@mixin date-time-range-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark-theme: map.get($color-config, is-dark);
  $divider: mat.get-color-from-palette($foreground, divider);
  $outline-color: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.3, 0.12));
  $outline-color-hover: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 1, 0.87));
  $text: mat.get-color-from-palette($foreground, text);
  $secondary-text: mat.get-color-from-palette($foreground, secondary-text);
  $disabled-text: mat.get-color-from-palette($foreground, disabled-text);
  $input-background: mat.get-color-from-palette($background, card);

  $font-size: 14px;
  $background-color: mat.get-color-from-palette($background, background);
  $disabled: mat.get-color-from-palette($foreground, disabled);

  app-date-time-range {
    margin: 10px 0;

    .faux-form-field-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      background-color: transparent;
      border-radius: 4px;
      border: 1px solid $outline-color;
      color: $text;
      width: calc(100% - 2px);
      cursor: text;
      padding: 8px 1px 1px;
      font-size: $font-size;

      transition: 250ms padding, 250ms border;

      &:hover {
        border-color: $outline-color-hover;
      }

      .label {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        height: 0;
        top: -14px;
        font-size: 0.8em;
        color: $secondary-text;
        z-index: 0;
        margin-left: 6px;

        &.disabled {
          color: $disabled-text;
        }

        & > .label-text {
          padding: 0 4px;
          height: $font-size;
          background-color: $input-background;
        }
      }

      .radio-buttons {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        .mat-radio-button {
          margin-left: 10px;
          padding: 5px 0;
        }

        .clear-input {
          margin-right: 10px;
          cursor: pointer;

          &.disabled {
            background-color: darken($outline-color, 50%);

            &:hover {
              cursor: not-allowed;
              background-color: darken($outline-color, 50%);

              mat-icon {
                color: $disabled-text;
              }
            }

            mat-icon {
              color: $disabled-text;
            }
          }

          &:hover {
            mat-icon {
              color: $text;
              background-color: darken($outline-color, 50%);
            }
          }

          mat-icon {
            transform: scale(0.75);
            color: $text;
            border-radius: 50%;
          }
        }
      }

      .date-time-group {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
      }

      .range-selection {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin: 0 10px;

        .mat-form-field {
          &.duration {
            max-width: 5em;
          }

          &:not(:first-of-type) {
            margin-left: 10px;
          }

          width: 0;
          flex: 1;

          &.time-field {
            max-width: 6.5em;
          }
        }
      }
    }
  }
}
