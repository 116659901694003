@use "sass:map";
@use "@angular/material" as mat;

@import "src/variables";
@import "@angular/material/theming";

@mixin admin-left-sidebar-theme($theme) {
  // Get color and typography config from theme
  $color-config: mat.get-color-config($theme);
  $is-dark-theme: map.get($color-config, is-dark);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $hover: mat.get-color-from-palette($background, hover, if($is-dark-theme, 0.3, 0.12));
  $divider: mat.get-color-from-palette($foreground, divider);

  app-admin-left-sidebar {
    .mat-nav-list {
      padding: 0;
      margin: 0;
    }

    .menu-header {
      border-bottom: 1px solid $divider;

      // Make this match the other headers (like filter drawer)
      &.mat-list-item-with-avatar.mat-list-item {
        height: 55px;
      }

      .title {
        font-weight: bold;
      }
    }

    .mat-list-item-with-avatar.mat-list-item {
      font-size: 0.9rem;
      user-select: none;
      &.active {
        background-color: $hover;
      }
    }

    .text-content {
      margin-left: 10px;

      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .description {
        font-size: 0.9em;
        margin-top: 0.2em;
        opacity: 0.7;
      }
    }
  }
}
