@use "sass:map";
@use "@angular/material" as mat;

@import "src/variables";
@import "@angular/material/theming";

@mixin district-group-search-dialog-theme($theme) {
  // Get color and typography config from theme
  $color-config: mat.get-color-config($theme);

  // Get typography values from config
  // TODO: Figure out how to use the typography mixin
  $font-size: 12px;
  $line-height: 16px;
  $font-weight: 400;

  // Grab relevant palettes from theme
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $is-dark-theme: map.get($color-config, is-dark);

  // Extract different colors for use in component
  $divider: mat.get-color-from-palette($foreground, divider);
  $text: mat.get-color-from-palette($foreground, text);
  $secondary-text: mat.get-color-from-palette($foreground, secondary-text);
  $input-background: mat.get-color-from-palette($background, card);
  $outline-color: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.3, 0.12));
  $outline-color-hover: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 1, 0.87));
  $disabled: mat.get-color-from-palette($foreground, disabled);
  $disabled-text: mat.get-color-from-palette($foreground, disabled-text);

  // Reusable variables for this component
  $border-radius: 4px;
  $nested-radius: $border-radius - 1px;

  // Mixins break encapsulation, so we need to scope all of these styles to the component
  app-district-group-search-dialog {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 14px;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      .title {
        font-size: 1.3em;
      }

      gaggle-icon {
        margin-right: 10px;
        cursor: pointer;
      }
    }

    .search {
      align-self: stretch;

      .mat-form-field {
        width: 100%;
      }
    }

    .single-select-note {
      font-size: 0.9em;
      font-style: italic;
    }

    .mat-dialog-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 10px;
      border-top: 1px solid $divider;
      border-bottom: 1px solid $divider;

      .list {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;

        .checkbox-label-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          min-height: 26px;

          .type-indicator {
            font-size: 0.8em;
            padding: 2px 4px;
            border: 1px solid $outline-color;
            border-radius: 5px;
            line-height: 1em;
          }

          .group-details {
            font-size: 0.8em;
            line-height: 24px;
          }

          .label-text {
            margin-left: 5px;
            line-height: 24px;
          }
        }

        .district-checkboxes {
          margin-left: 5px;
          align-self: stretch;
          border-radius: 0;
          padding: 3px;
          border-bottom: 0.5px solid $divider;

          .mat-expansion-panel-header {
            padding: 0;

            .mat-checkbox {
              max-width: 98%;
              overflow: hidden;
            }
          }

          .group-checkboxes {
            margin-left: 25px;
          }

          .mat-expansion-panel-body {
            padding: 0;
          }
        }
      }

      .searching {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-self: stretch;
        align-items: center;
        justify-content: center;

        .mat-spinner {
          margin-bottom: 16px;
        }
      }
    }
  }
}
